
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import moment from 'moment'
import { IScheduleDateTime } from '@/utils/types'
import { TIMEZONE_LIST, TIME_SELECT_LIST } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:onUpdate'],
})
export default class SearchUserInput extends Vue {
  @Prop()
  schedule!: IScheduleDateTime

  TIME_SELECT_LIST = TIME_SELECT_LIST
  TIMEZONE_LIST = TIMEZONE_LIST

  selectedDate!: moment.Moment
  selectedTime = ''
  selectedTimezone = '+09:00'
  componentKey = 1

  selectedSchedule: IScheduleDateTime = {
    timestamp: 0,
    timezone: '',
  }

  get loading() {
    return this.$store.state.board.loading
  }

  @Emit('update:onUpdate')
  @Watch('selectedSchedule')
  selectedScheduleChanged() {
    return this.selectedSchedule
  }

  setSelectedSchedule() {
    // Format: 2013-11-18T06:55:00-05:00
    const dateStr = moment(this.selectedDate).format('YYYY-MM-DD')
    const timestamp = moment(`${dateStr}T${this.selectedTime}:00${this.selectedTimezone}`).unix()

    this.selectedSchedule = {
      timestamp,
      timezone: this.selectedTimezone,
    }
  }

  onSelectedDateChanged(value: moment.Moment) {
    this.selectedDate = value
    this.setSelectedSchedule()
    this.componentKey++
  }

  onSelectedTimeChanged() {
    this.setSelectedSchedule()
    this.componentKey++
  }

  onSelectedTimezoneChanged() {
    this.setSelectedSchedule()
    this.componentKey++
  }

  @Watch('schedule')
  scheduleChanged() {
    this.selectedTimezone = this.schedule.timezone
    const momentDatetime = moment.unix(this.schedule.timestamp).utcOffset(this.schedule.timezone)
    this.selectedDate = momentDatetime
    this.selectedTime = momentDatetime.format('HH:mm')
    this.selectedSchedule = this.schedule
    this.componentKey++
  }

  created() {
    this.scheduleChanged()
  }
}
