<template>
  <a-modal
    width="1000px"
    class="max-w-full"
    :visible="modalVisible"
    @cancel="onCancel"
    title="Create new Sango CorkBoard"
  >
    <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
      <h2 class="text-xl text-gray-400">{{ form.title }}</h2>

      <hr />
      <h2 class="text-md">
        Recipients infomation
        <a-tooltip placement="topLeft" title="Who do you want to send nice messages to?">
          <QuestionCircleOutlined />
        </a-tooltip>
      </h2>
      <hr />
      <a-form-item
        v-for="(recipient, index) in form.recipients"
        :key="recipient.key"
        :label="index === 0 ? 'Recipients' : ''"
        :name="['recipients', index, 'name']"
        :rules="{
          required: true,
          validator: validateRecipients,
          message: 'Recipient name and email can not be null',
          trigger: 'blur',
        }"
      >
        <div class="flex gap-2">
          <a-input v-model:value="recipient.name" placeholder="recipient name" class="flex-grow" />
          <a-input v-model:value="recipient.email" placeholder="recipient email" class="flex-grow" />
          <a-button danger shape="circle" @click="removeRecipient(recipient)" :disabled="form.recipients.length <= 1">
            <template #icon>
              <DeleteOutlined class="dynamic-delete-button" :disabled="form.recipients.length === 1" />
            </template>
          </a-button>
        </div>
      </a-form-item>

      <a-form-item>
        <a-button type="dashed" @click="addRecipient">
          <PlusOutlined />
          Add Recipient
        </a-button>
      </a-form-item>

      <!-- To Recipients delivery schedule -->
      <a-form-item label="To recipients delivery schedule">
        <a-radio-group v-model:value="form.toRecipientsScheduleType" button-style="solid">
          <a-radio-button :value="BOARD_DELIVERY_TYPE.now">Delivery now</a-radio-button>
          <a-radio-button :value="BOARD_DELIVERY_TYPE.schedule">Set Schedule</a-radio-button>
        </a-radio-group>
      </a-form-item>

      <ScheduleForm
        @update:onUpdate="toRecipientsScheduleUpdate"
        :schedule="form.toRecipientsSchedule"
        v-if="form.toRecipientsScheduleType === BOARD_DELIVERY_TYPE.schedule"
      />

      <hr />
      <h2 class="text-md">
        Contributors infomation
        <a-tooltip placement="topLeft" title="Who will add card to this board">
          <QuestionCircleOutlined />
        </a-tooltip>
      </h2>
      <hr class="mb-4" />

      <template v-if="form.boardType === BOARD_PERMISSION_TYPE.private">
        <SearchUserInput @update:onUpdate="onAddNewPermissionEmail" :emailList="form.contributorEmails" />

        <!-- To contributors delivery schedule -->
        <a-form-item label="To contributors delivery schedule">
          <a-radio-group v-model:value="form.toContributorsScheduleType" button-style="solid">
            <a-radio-button :value="BOARD_DELIVERY_TYPE.now">Delivery now</a-radio-button>
            <a-radio-button :value="BOARD_DELIVERY_TYPE.schedule">Set Schedule</a-radio-button>
          </a-radio-group>
        </a-form-item>

        <ScheduleForm
          @update:onUpdate="toContributorsScheduleUpdate"
          :schedule="form.toContributorsSchedule"
          v-if="form.toContributorsScheduleType === BOARD_DELIVERY_TYPE.schedule"
        />
      </template>

      <a-alert
        v-else
        message="This board is not a private board, so no schedule will send to contributors.
        Let's share board edit link to someone."
        type="info"
        show-icon
      />

      <hr />
    </a-form>

    <template #footer>
      <a-button key="back" @click="onCancel">Cancel</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="onSubmitBoard"> Save </a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IBoard, IScheduleDateTime } from '@/utils/types'
import { UPDATE_BOARD } from '@/store/actions'
import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined, LockOutlined } from '@ant-design/icons-vue'
import { BOARD_DELIVERY_TYPE } from '@/utils/constants'
import SearchUserInput from '@/components/user/SearchUserInput.vue'
import ScheduleForm from '@/components/common/ScheduleForm.vue'
import BoardFormMixin from '@/components/board/mixins/BoardFormMixin.vue'

import moment from 'moment'

@Options({
  components: {
    SearchUserInput,
    ScheduleForm,
    QuestionCircleOutlined,
    DeleteOutlined,
    LockOutlined,
    PlusOutlined,
  },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardFormScheduleModal extends BoardFormMixin {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  defaultSchedule: IScheduleDateTime = {
    timestamp: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(1, 'days').unix(),
    timezone: '+09:00',
  }

  form: IBoard = {
    recipients: [
      {
        key: Date.now(),
        name: '',
        email: '',
      },
    ],
    toRecipientsSchedule: this.defaultSchedule,
    toRecipientsScheduleType: BOARD_DELIVERY_TYPE.schedule,

    toContributorsScheduleType: BOARD_DELIVERY_TYPE.now,
    toContributorsSchedule: this.defaultSchedule,

    contributorEmails: [] as string[],
    sentContributors: false,
    sentRecipients: false,
    // Ready to send mail so on
    isBoardReady: true,
  }

  onAddNewPermissionEmail(emails: string[]) {
    this.form.contributorEmails = emails
  }

  // Sub-component emit event handler
  toRecipientsScheduleUpdate(schedule: IScheduleDateTime) {
    this.form.toRecipientsSchedule = schedule
  }

  // Sub-component emit event handler
  toContributorsScheduleUpdate(schedule: IScheduleDateTime) {
    this.form.toContributorsSchedule = schedule
  }

  onSubmitBoard() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doSaveBoard()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSaveBoard() {
    // Reset email send status if updated
    this.form.sentContributors = false
    this.form.sentRecipients = false
    this.form.isBoardReady = true
    await this.$store.dispatch(UPDATE_BOARD, this.form)

    // Reload board list
    this.getBoards()
    this.onCancel()
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async created() {
    if (!this.id) {
      return
    }

    await this.getBoard()
    this.form = { ...this.form, ...JSON.parse(JSON.stringify(this.board)) }
    // Set default schedule
    this.form.toContributorsSchedule = this.form.toContributorsSchedule || this.defaultSchedule
    this.form.toRecipientsSchedule = this.form.toRecipientsSchedule || this.defaultSchedule
  }
}
</script>
