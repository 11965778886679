
import { Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IBoard, IScheduleDateTime } from '@/utils/types'
import { UPDATE_BOARD } from '@/store/actions'
import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined, LockOutlined } from '@ant-design/icons-vue'
import { BOARD_DELIVERY_TYPE } from '@/utils/constants'
import SearchUserInput from '@/components/user/SearchUserInput.vue'
import ScheduleForm from '@/components/common/ScheduleForm.vue'
import BoardFormMixin from '@/components/board/mixins/BoardFormMixin.vue'

import moment from 'moment'

@Options({
  components: {
    SearchUserInput,
    ScheduleForm,
    QuestionCircleOutlined,
    DeleteOutlined,
    LockOutlined,
    PlusOutlined,
  },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardFormScheduleModal extends BoardFormMixin {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  defaultSchedule: IScheduleDateTime = {
    timestamp: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).add(1, 'days').unix(),
    timezone: '+09:00',
  }

  form: IBoard = {
    recipients: [
      {
        key: Date.now(),
        name: '',
        email: '',
      },
    ],
    toRecipientsSchedule: this.defaultSchedule,
    toRecipientsScheduleType: BOARD_DELIVERY_TYPE.schedule,

    toContributorsScheduleType: BOARD_DELIVERY_TYPE.now,
    toContributorsSchedule: this.defaultSchedule,

    contributorEmails: [] as string[],
    sentContributors: false,
    sentRecipients: false,
    // Ready to send mail so on
    isBoardReady: true,
  }

  onAddNewPermissionEmail(emails: string[]) {
    this.form.contributorEmails = emails
  }

  // Sub-component emit event handler
  toRecipientsScheduleUpdate(schedule: IScheduleDateTime) {
    this.form.toRecipientsSchedule = schedule
  }

  // Sub-component emit event handler
  toContributorsScheduleUpdate(schedule: IScheduleDateTime) {
    this.form.toContributorsSchedule = schedule
  }

  onSubmitBoard() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doSaveBoard()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSaveBoard() {
    // Reset email send status if updated
    this.form.sentContributors = false
    this.form.sentRecipients = false
    this.form.isBoardReady = true
    await this.$store.dispatch(UPDATE_BOARD, this.form)

    // Reload board list
    this.getBoards()
    this.onCancel()
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async created() {
    if (!this.id) {
      return
    }

    await this.getBoard()
    this.form = { ...this.form, ...JSON.parse(JSON.stringify(this.board)) }
    // Set default schedule
    this.form.toContributorsSchedule = this.form.toContributorsSchedule || this.defaultSchedule
    this.form.toRecipientsSchedule = this.form.toRecipientsSchedule || this.defaultSchedule
  }
}
