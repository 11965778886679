
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IBoard, IUser } from '@/utils/types'
import { CopyOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'

@Options({
  components: { CopyOutlined },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class BoardShareModal extends Vue {
  @Prop()
  board!: IBoard

  @Prop()
  modalVisible!: boolean

  get loading() {
    return this.$store.state.board.loading
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get isOwner() {
    return this.userInfo.id === this.board.userId
  }

  get shareLinkView(): string {
    const path = this.$router.resolve({
      name: 'board_view',
      params: { id: this.board.id },
    })?.fullPath

    return location.origin + path
  }

  get shareLinkEdit(): string {
    const path = this.$router.resolve({
      name: 'board_edit',
      params: { id: this.board.id },
    })?.fullPath

    return location.origin + path
  }

  copySuccess(): void {
    message.info('Copied')
  }

  handleCopyLinkView(): void {
    navigator.clipboard.writeText(this.shareLinkView)
    this.copySuccess()
  }

  handleCopyLinkEdit(): void {
    navigator.clipboard.writeText(this.shareLinkEdit)
    this.copySuccess()
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }
}
