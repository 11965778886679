<template>
  <div class="bg-gray-200 p-2" v-if="componentKey">
    <div class="flex gap-2 flex-wrap">
      <a-date-picker v-model:value="selectedDate" @change="onSelectedDateChanged" class="flex-1" />

      <!-- Select time -->
      <a-select v-model:value="selectedTime" @change="onSelectedTimeChanged" class="flex-1">
        <a-select-option v-for="(title, value) in TIME_SELECT_LIST" :key="value" :value="value">
          {{ title }}
        </a-select-option>
      </a-select>

      <!-- // Select timezone -->
      <a-select v-model:value="selectedTimezone" @change="onSelectedTimezoneChanged" class="w-full">
        <a-select-option v-for="(title, value) in TIMEZONE_LIST" :key="value" :value="value">
          {{ title }}
        </a-select-option>
      </a-select>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import moment from 'moment'
import { IScheduleDateTime } from '@/utils/types'
import { TIMEZONE_LIST, TIME_SELECT_LIST } from '@/utils/constants'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:onUpdate'],
})
export default class SearchUserInput extends Vue {
  @Prop()
  schedule!: IScheduleDateTime

  TIME_SELECT_LIST = TIME_SELECT_LIST
  TIMEZONE_LIST = TIMEZONE_LIST

  selectedDate!: moment.Moment
  selectedTime = ''
  selectedTimezone = '+09:00'
  componentKey = 1

  selectedSchedule: IScheduleDateTime = {
    timestamp: 0,
    timezone: '',
  }

  get loading() {
    return this.$store.state.board.loading
  }

  @Emit('update:onUpdate')
  @Watch('selectedSchedule')
  selectedScheduleChanged() {
    return this.selectedSchedule
  }

  setSelectedSchedule() {
    // Format: 2013-11-18T06:55:00-05:00
    const dateStr = moment(this.selectedDate).format('YYYY-MM-DD')
    const timestamp = moment(`${dateStr}T${this.selectedTime}:00${this.selectedTimezone}`).unix()

    this.selectedSchedule = {
      timestamp,
      timezone: this.selectedTimezone,
    }
  }

  onSelectedDateChanged(value: moment.Moment) {
    this.selectedDate = value
    this.setSelectedSchedule()
    this.componentKey++
  }

  onSelectedTimeChanged() {
    this.setSelectedSchedule()
    this.componentKey++
  }

  onSelectedTimezoneChanged() {
    this.setSelectedSchedule()
    this.componentKey++
  }

  @Watch('schedule')
  scheduleChanged() {
    this.selectedTimezone = this.schedule.timezone
    const momentDatetime = moment.unix(this.schedule.timestamp).utcOffset(this.schedule.timezone)
    this.selectedDate = momentDatetime
    this.selectedTime = momentDatetime.format('HH:mm')
    this.selectedSchedule = this.schedule
    this.componentKey++
  }

  created() {
    this.scheduleChanged()
  }
}
</script>
